
import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {setPageTitle, handleErrorResponse, formatBytes} from '../../routes/lm.js';
import download from 'downloadjs';

const UGDownload = () => {
  const [status, setStatus] = useState(null);
  const [files, setFiles] = useState([{file: '', size: 0},]);
  const user = JSON.parse(localStorage.getItem('user'));
  
  setPageTitle('Download Multiplier');

  //Hook to get data from backend server
  useEffect ( () => {
     fetchInstallFiles();
  }, []);  
  
  const fetchInstallFiles = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/userguide/latest/release/bz2files', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
	  console.log(res);
          if(res.status === 200) {
            setFiles(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    }
    
  }  
  const myElement = (
    <div className="userguide">
      <h1>Download Multiplier</h1>
      <br/>
      Download LM Tools <b>Multiplier</b> component from below Download link and follow 
      the <a href="/userguide/install/multiplier">multiplier installation</a> instructions page to install the same.
      Also check the <a href="/userguide/multiplier/machineinfo">Multiplier machine</a> specification before installation.
      <br/><br/>
      
      <div className="statstbl">
        <h3><p align="center">LM Tools Release Candidates</p></h3>

        <table style={{ tableLayout: 'fixed', width: '100%' }}>
          
          <thead>
            
            <tr key={"rhead"}>
              <th style={{width: "10%"}}>
                Sl
              </th>
              
              <th style={{width: "60%"}}>
                File
              </th>
              
              <th style={{width: "30%"}}>
                Size
              </th>
            </tr>
            
          </thead>
          
          <tbody>
            {files.map((val,tindex) => (
              <tr key={"row" + tindex}>
                <td>
                  {tindex+1}
                </td>
                
                <td>
                  <a href="#"><span onClick={() => downloadLatestRelease(user, val.file).finally(() => {}) }>{val.file}</span></a>
                </td>
                
                <td>
                  {formatBytes(val.size)}
                </td>
              </tr>
            ))}
            
          </tbody>
        </table>
      </div>
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/introduction" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/multiplier/machineinfo" }>Next</button>
      </div>
      <br/>
      <div className="inlinecenter small-text">
        {status && <div className={`alert ${status.type}`}>{status.message}</div>}
      </div>
      
    </div>
  );
  
  return myElement;
}

async function downloadLatestRelease(user, file) {
  
  try {
    
    const res = await fetch(
      '/api/userguide/latest/release/bz2file/', {
        method: 'post',
        headers: { 'Content-Type': 'application/json',  'Authorization': user.jwt,},
        body: JSON.stringify({
          uid: user.uid, 
          email: user.email, 
          file: file, 
        }),
      },
    );
    
    //const res = await fetch('/api/userguide/latest/release/bz2file/');
    
    const blob = await res.blob();
    download(blob, file);
  } catch (err) {
    console.log(err);
  }
}

export {UGDownload};
  
